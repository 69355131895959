import { render, staticRenderFns } from "./DIY_4-2.vue?vue&type=template&id=ab230cf6&scoped=true&"
import script from "./DIY_4-2.vue?vue&type=script&lang=js&"
export * from "./DIY_4-2.vue?vue&type=script&lang=js&"
import style0 from "../css/styles.css?vue&type=style&index=0&id=ab230cf6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ab230cf6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
installComponents(component, {VCard,VContainer,VDialog})
